:root {
    --vlGrayBlue: hsl(240, 78%, 98%);
    --lGrayBlue: hsl(234, 14%, 74%);
    --GrayBlue: hsl(233, 13%, 49%);
    --DGrayBlue: hsl(232, 13%, 33%);
    --grad: linear-gradient(to bottom right, hsl(236, 72%, 79%), hsl(237, 63%, 64%));
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    font-weight: 700;
    scroll-behavior: smooth;
}

.flexed {
    display: flex;
    align-items: center;
    justify-content: center;
}